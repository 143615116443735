import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { onMessage } from 'firebase/messaging';
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const FirebaseData = () => {

  const setting = useSelector((state) => state.setting);


  if (setting.setting === null) {
    return <Loader screen='full' />;
  }

  const apiKey = setting.setting && setting.setting.firebase.apiKey;
  const authDomain = setting.setting && setting.setting.firebase.authDomain;
  const projectId = setting.setting && setting.setting.firebase.projectId;
  const storageBucket = setting.setting && setting.setting.firebase.storageBucket;
  const messagingSenderId = setting.setting && setting.setting.firebase.messagingSenderId;
  const appId = setting.setting && setting.setting.firebase.appId;
  const measurementId = setting.setting && setting.setting.firebase.measurementId;


  const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId,
  };
 

/*
  const firebaseConfig = {
    apiKey: "AIzaSyCZ2ltLVJhTJWREvE72Vrx6vdg0xN-Nci8",
    authDomain: "masamadre-ab839.firebaseapp.com",
    projectId: "masamadre-ab839",
    storageBucket: "masamadre-ab839.appspot.com",
    messagingSenderId: "495790281049",
    appId: "1:495790281049:web:5cadfe7986515a6b95fc48",
    measurementId: "G-3HYHCXVRVX"
  };
*/
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  const auth = firebase.auth();

  const messaging = firebase.messaging();

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
  });

  return { auth, firebase, messaging };
};

export default FirebaseData;
